import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import WithdrawForm from "./WithdrawForm";
import CSKHCard from "../Deposit/CSKHCard";

const WithdrawPage = observer(() => {
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, general },
  } = useStores();

  // if (Number(general?.deposit_cskh) === 1)
  //   return (
  //     <>
  //       <HeaderTitle isBack title="Rút tiền" />
  //       <div className="page-container">
  //         <CSKHCard />
  //       </div>
  //     </>
  //   );

  return (
    <>
      <HeaderTitle isBack title="Rút tiền" />
      <WithdrawForm />
    </>
  );
});

export default WithdrawPage;
