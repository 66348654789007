import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { formatTimeSecond } from "_common/utils/datetimeFormat";
import { get, random } from "lodash";
import { formatNumber } from "_common/utils/formatValue";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faClock } from "@fortawesome/free-solid-svg-icons";

import ModalGameHelp from "./ModalGameHelp";

const GameInfo = observer(({ gameInfo, dataGame, gameDetail }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { games, user },
  } = useStores();
  const [open, setOpen] = useState();

  const timeText = useMemo(() => {
    let time = Number(dataGame?.time);

    if (time < 0) {
      time = 0;
    }
    const textTime = formatTimeSecond(time);

    return textTime.split(":");
  }, [dataGame?.time]);

  const randomOnline = useMemo(() => random(1000, 5000), dataGame?.time);

  return (
    <>
      <div className="game-lead">
        <div className="game-info">
          <div className="item">
            <div className="text-center">
              <p className="game-no">
                <span>THỜI GIAN</span> <b>{gameInfo?.game_no}</b>
              </p>
              <p>GIAO DỊCH</p>
            </div>

            <div className="time">
              {dataGame?.status === "start" ? (
                <>
                  <b>{timeText?.[0]}</b>
                  <span>Phút</span>
                  <b>{timeText?.[1]}</b>
                  <span>Giây</span>
                </>
              ) : (
                <span>
                  <FontAwesomeIcon icon={faClock} />
                  Mở thưởng
                </span>
              )}
            </div>
          </div>

          <div className="item right">
            <div className="user-info">
              <img src={user?.avatar_url} alt="" />
              <div className="info">
                <h5>{user?.full_name}</h5>
                <p>Tài khoản: {user?.username}</p>
              </div>
            </div>
            <h5>SỐ DƯ: {formatNumber(user?.balance)}</h5>
            <p>Trực tuyến: {formatNumber(randomOnline)}</p>
          </div>
        </div>
        <div className="game-prev">
          <p className="game-no">
            <span>Số kỳ</span>
            <b>{gameInfo?.prev_game?.game_no}</b>
          </p>
          <div className="lotteryNumber" onClick={() => setOpen(!open)}>
            <span className="ball">
              {get(gameInfo, "prev_game.number1", 1)}
            </span>
            <span className="dau">+</span>
            <span className="ball">
              {get(gameInfo, "prev_game.number2", 2)}
            </span>
            <span className="dau">+</span>
            <span className="ball">
              {get(gameInfo, "prev_game.number3", 3)}
            </span>
            <span className="dau">=</span>
            <span className="ball red">
              {get(gameInfo, "prev_game.total_number", 3)}
            </span>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      </div>
      {open && (
        <ModalGameHelp
          open={open}
          onClose={() => setOpen(!open)}
          gameInfo={gameInfo}
        />
      )}
    </>
  );
});

export default GameInfo;
