import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import lhc from "assets/img/lhc.png";
import { formatNumber } from "_common/utils/formatValue";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const lastName = [
  "Nguyễn",
  "Lê",
  "Hoàng",
  "Ngô",
  "Trần",
  "Phùng",
  "Bùi",
  "Đặng",
  "Đỗ",
  "Hồ",
  "Dương",
  "Đinh",
  "Phạm",
  "Huynh",
  "Võ",
];

const middleName = [
  "Văn",
  "Thị",
  "Ngọc",
  "Hùng",
  "Quang",
  "Tuấn",
  "Quỳnh",
  "Thị Thanh",
  "Thuỷ",
  "Công",
  "Đức",
  "Hồng",
  "Kim",
  "Thị Khanh",
];

const firstName = [
  "Cường",
  "Tùng",
  "Thắng",
  "Huy",
  "Sơn",
  "Hưng",
  "Dũng",
  "Hiếu",
  "Toàn",
  "Linh",
  "Hiệp",
  "Luân",
  "Hậu",
  "Lộc",
  "Minh",
  "Lâm",
  "Nguyên",
  "Ngọc",
  "An",
  "Anh",
  "Lục",
  "Ngọc Hoàng",
  "Khôi",
  "Phong",
  "Lợi",
  "Hồng",
  "Lan Anh",
  "Vân Anh",
  "Anh Thư",
  "Hiền",
  "Diệu",
  "Ánh Ngọc",
  "Nguyệt",
  "Ly",
  "Gia Hân",
  "Trúc",
  "Thuý",
  "Trang",
  "Uyên",
  "Nga",
];

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function GameHis({ gameInfo }) {
  const navigate = useNavigate();
  const {
    authStore: { avatars, item_ball, item_number },
  } = useStores();
  const ref = useRef(null);

  const [ran, setRan] = useState();
  const [listItem, setListItem] = useState();

  const randomFunct = () => {
    const lstName = [...listItem];
    const indexLastName = randomInteger(0, lastName.length - 1);
    const indexMiddleName = randomInteger(0, middleName.length - 1);
    const indexFirstName = randomInteger(0, firstName.length - 1);
    const indexAvatar = randomInteger(0, avatars.length - 1);
    const money = randomInteger(100, 100000);
    const listBet = [...item_ball, ...item_number];
    const indexBet = randomInteger(0, listBet.length - 1);

    lstName.push({
      id: new Date().getTime(),
      game_no: gameInfo?.game_no,
      bet: listBet[indexBet]?.name,
      avatar: avatars[indexAvatar],
      name: `${lastName[indexLastName]} ${middleName[indexMiddleName]} ${firstName[indexFirstName]}`,
      money: money * 1000,
      time: moment().format("HH:mm:ss"),
    });

    setListItem(lstName);
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const lstName = [];

    for (let index = 0; index < 30; index += 1) {
      const indexLastName = randomInteger(0, lastName.length - 1);
      const indexMiddleName = randomInteger(0, middleName.length - 1);
      const indexFirstName = randomInteger(0, firstName.length - 1);
      const indexAvatar = randomInteger(0, avatars.length - 1);
      const money = randomInteger(100, 100000);
      const timeRandom = randomInteger(10, 30);
      const listBet = [...item_ball, ...item_number];
      const indexBet = randomInteger(0, listBet.length - 1);

      lstName.push({
        id: index,
        game_no: gameInfo?.game_no,
        avatar: avatars[indexAvatar],
        bet: listBet[indexBet]?.name,
        name: `${lastName[indexLastName]} ${middleName[indexMiddleName]} ${firstName[indexFirstName]}`,
        money: money * 1000,
        time: moment()
          .subtract("minutes", Math.round((30 - index) / 15))
          .add("seconds", timeRandom)
          .format("HH:mm:ss"),
      });
    }
    setListItem(lstName);
    ref.current.scrollIntoView({ behavior: "smooth" });
  }, [gameInfo?.game_no]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRan(new Date().getTime());
      randomFunct();
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [ran]);

  return (
    <div className="player-list ">
      <ul className="inner-wrap">
        {listItem?.map((item, index) => (
          <li className="winner-item" key={`item-${index.toString()}`}>
            <div className="left">
              <img src={item?.avatar} alt="" />
            </div>
            <div className="right">
              <h5 className="account">
                {item?.name} <span className="time">{item?.time}</span>
              </h5>
              <div className="content">
                <p>
                  <FontAwesomeIcon icon={faClock} /> Kỳ {item?.game_no}
                </p>
                <p>GIAO DỊCH</p>
                <p>
                  {item?.bet} / {formatNumber(item?.money)}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="msga " ref={ref}>
        Chỉ hiển thị 50 hồ sơ cạnh tranh đầu tiên！
      </div>
    </div>
  );
}
