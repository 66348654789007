import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faMoneyBillTransfer,
  faWallet,
  faRotate,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatCurrency, formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { positionKeyText } from "_common/constants/statusType";
import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";

import level1 from "assets/img/level/1.png";
import level2 from "assets/img/level/2.png";
import level3 from "assets/img/level/3.png";
import level4 from "assets/img/level/4.png";
import level5 from "assets/img/level/5.png";
import level6 from "assets/img/level/6.png";
import level7 from "assets/img/level/7.png";
import level8 from "assets/img/level/8.png";
import level9 from "assets/img/level/9.png";
import level10 from "assets/img/level/10.png";

import icon1 from "assets/img/icon/user-tz.png";
import icon2 from "assets/img/icon/user-zd.png";
import icon3 from "assets/img/icon/user-fx.png";
import icon4 from "assets/img/icon/user-tx.png";
import icon5 from "assets/img/icon/user-lczd.png";
import icon6 from "assets/img/icon/user-xzjl.png";

const levelMap = {
  1: level1,
  2: level2,
  3: level3,
  4: level4,
  5: level5,
  6: level6,
  7: level7,
  8: level8,
  9: level9,
  10: level10,
};

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, user_wallet, logo, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage = error?.message ?? "Vui lòng thử lại!";
      NotificationManager.error(errorMessage);
    },
  });

  const getProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <HeaderTitle isBack title="Cá nhân" />
      <div className="page-container">
        <div className="personal-page">
          <div className="header">
            <div className="user-card">
              <div className="company-info">
                <div className="company-name">VINPEARL</div>
              </div>
              <div className="user-info">
                <div className="vip-icon">
                  <img src={logo} alt="" />
                </div>
                <div className="user-option">
                  <div className="user-option-item">
                    <span>Tên người dùng:</span>
                    <span>{user?.full_name} </span>
                  </div>
                  <div className="user-option-item">
                    <span>Tài khoản:</span>
                    <span>{user?.id} </span>
                  </div>
                  <div className="user-option-item">
                    <span>SỐ DƯ:</span>
                    <span>{formatNumber(user_wallet?.amount)}</span>
                  </div>
                </div>
                <div className="level-img">
                  <img src={levelMap[user?.level]} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="cell-list">
              <Link to="/payment/deposit" className="cell-item">
                <div className="icon">
                  <img src={icon1} alt="" />
                </div>
                <div className="name">Nạp tiền trực tuyến</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/payment/withdraw" className="cell-item">
                <div className="icon">
                  <img src={icon2} alt="" />
                </div>
                <div className="name">Rút tiền nhanh</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/histories/deposit" className="cell-item">
                <div className="icon">
                  <img src={icon3} alt="" />
                </div>
                <div className="name">Lịch sử nạp tiền</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/histories/withdraw" className="cell-item">
                <div className="icon">
                  <img src={icon4} alt="" />
                </div>
                <div className="name">Lịch sử rút tiền</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/histories/game" className="cell-item">
                <div className="icon">
                  <img src={icon5} alt="" />
                </div>
                <div className="name">Lịch sử giao dịch</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/histories/report" className="cell-item">
                <div className="icon">
                  <img src={icon1} alt="" />
                </div>
                <div className="name">Hồ sơ lợi nhuận</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/payment/bank" className="cell-item">
                <div className="icon">
                  <img src={icon6} alt="" />
                </div>
                <div className="name">Liên kết ngân hàng</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
              <Link to="/member/change-password" className="cell-item">
                <div className="icon">
                  <img src={icon6} alt="" />
                </div>
                <div className="name">Thay đổi mật khẩu</div>
                <span className="arrow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </span>
              </Link>
            </div>
          </div>
          <div className="member-footer">
            <button
              type="button"
              className="btn btn-logout"
              onClick={() => onLogout()}
            >
              Đăng xuất an toàn
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default MemberPage;
