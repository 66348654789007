import React from "react";
import { Link } from "react-router-dom";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";

function LevelPage() {
  const {
    authStore: { games },
  } = useStores();
  return (
    <>
      <HeaderTitle title="Lựa chọn hội trường " />
      <div className="page-container">
        <div className="level-page">
          {games?.map((item) => (
            <Link to={item?.url} alt="" key={item?.id}>
              <img src={item?.image_url} alt="" />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default LevelPage;
