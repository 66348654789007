import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import lhc from "assets/img/lhc.png";
import { formatNumber } from "_common/utils/formatValue";

const lastName = [
  "Nguyễn",
  "Lê",
  "Hoàng",
  "Ngô",
  "Trần",
  "Phùng",
  "Bùi",
  "Đặng",
  "Đỗ",
  "Hồ",
  "Dương",
  "Đinh",
  "Phạm",
  "Huynh",
  "Võ",
];

const middleName = [
  "Văn",
  "Thị",
  "Ngọc",
  "Hùng",
  "Quang",
  "Tuấn",
  "Quỳnh",
  "Thị Thanh",
  "Thuỷ",
  "Công",
  "Đức",
  "Hồng",
  "Kim",
  "Thị Khanh",
];

const firstName = [
  "Cường",
  "Tùng",
  "Thắng",
  "Huy",
  "Sơn",
  "Hưng",
  "Dũng",
  "Hiếu",
  "Toàn",
  "Linh",
  "Hiệp",
  "Luân",
  "Hậu",
  "Lộc",
  "Minh",
  "Lâm",
  "Nguyên",
  "Ngọc",
  "An",
  "Anh",
  "Lục",
  "Ngọc Hoàng",
  "Khôi",
  "Phong",
  "Lợi",
  "Hồng",
  "Lan Anh",
  "Vân Anh",
  "Anh Thư",
  "Hiền",
  "Diệu",
  "Ánh Ngọc",
  "Nguyệt",
  "Ly",
  "Gia Hân",
  "Trúc",
  "Thuý",
  "Trang",
  "Uyên",
  "Nga",
];

export default function Profit() {
  const navigate = useNavigate();
  const {
    authStore: { order_random },
  } = useStores();

  const [ran, setRan] = useState();

  const renderItem = useMemo(() => {
    const lstName = [];

    function randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    for (let index = 0; index < 30; index += 1) {
      const indexLastName = randomInteger(0, lastName.length - 1);
      const indexMiddleName = randomInteger(0, middleName.length - 1);
      const indexFirstName = randomInteger(0, firstName.length - 1);
      const code = randomInteger(100, 999);
      const money = randomInteger(100, 100000);

      lstName.push({
        id: index,
        code,
        name: `${lastName[indexLastName]} ${middleName[indexMiddleName]} ${firstName[indexFirstName]}`,
        money: money * 1000,
      });
    }

    return lstName?.map((item, index) => (
      <li className="winner-item" key={`item-${index.toString()}`}>
        <div className="left">
          <div className="name">{item?.name} </div>
          <div className="account">Tên người dùng: {item?.code}****</div>
        </div>
        <div className="right">
          <span>+</span>
          <span>{formatNumber(item?.money)}</span> VND
        </div>
      </li>
    ));
  }, [ran]);

  const renderItem2 = useMemo(() => {
    const lstName = [];

    function randomInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }

    for (let index = 0; index < 30; index += 1) {
      const indexLastName = randomInteger(0, lastName.length - 1);
      const indexMiddleName = randomInteger(0, middleName.length - 1);
      const indexFirstName = randomInteger(0, firstName.length - 1);
      const code = randomInteger(100, 999);
      const money = randomInteger(100000, 9999999);

      lstName.push({
        id: index,
        code,
        name: `${lastName[indexLastName]} ${middleName[indexMiddleName]} ${firstName[indexFirstName]}`,
        money,
      });
    }

    return lstName?.map((item, index) => (
      <li className="winner-item" key={`item-${index.toString()}`}>
        <div className="left">
          <div className="name">{item?.name} </div>
          <div className="account">Tên người dùng: {item?.code}****</div>
        </div>
        <div className="right">
          <span>+</span>
          <span>{formatNumber(item?.money)}</span> VND
        </div>
      </li>
    ));
  }, [ran]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRan(new Date().getTime());
    }, 50000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="list-wrap">
      <div className="winner-list-wrap">
        <div className="title">
          <div className="icon">
            <img src={lhc} alt="" />
          </div>
          <div className="text">Danh sách lợi nhuận</div>
        </div>
        <div className="winner-list ">
          <ul className="inner-wrap targetUp">{renderItem}</ul>
          <ul className="inner-wrap targetUp">{renderItem2}</ul>
        </div>
      </div>
    </div>
  );
}
