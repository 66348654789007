import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import classNames from "classnames";
import { get } from "lodash";
import { useNotify } from "_common/component/NotifyProvider";
import { formatTimeSecond } from "_common/utils/datetimeFormat";
import ModalBet from "./ModalBet";
import ModalInfo from "./ModalInfo";

function Game() {
  const {
    authStore: { user, games, bet_list, bet_types },
  } = useStores();
  const { dataGame, setRoomId, pushNotify, onLeaveRoom, onJoinRoom } =
    useNotify();

  const [openModalInfo, setModalInfo] = useState(false);
  const [openModalNotify, setModalNotify] = useState(false);
  const [openModalBet, setModalBet] = useState(false);
  const [activeTab, setActiveTab] = useState(get(games[0], "room_id"));
  const [item_type, setItemType] = useState(Object.keys(bet_list)[0]);
  const [item_bet, setItemBet] = useState();

  const handleChangeGame = (id) => {
    setActiveTab(id);
    setRoomId(id);
  };

  const handleChangeType = (value) => {
    setItemType(value);
  };

  const handleOpenBet = (value) => {
    setItemBet(value);
    setModalBet(true);
  };

  useEffect(() => {
    setRoomId("level_1");
    return () => {
      setRoomId();
    };
  }, []);

  // useEffect(() => {
  //   if (openModalNotify) return;
  //   if (dataGame?.time < 3) {
  //     setModalNotify(true);
  //   }
  // }, [dataGame?.time, openModalNotify]);

  return (
    <div className="board-sticky">
      <div className="game-navbar">
        <div className="nav-item left-item">
          Kỳ hàng tiếp theo
          <b className="lotId">{dataGame?.game_no}</b>
        </div>
        <div>|</div>
        <div className="nav-item right-item">
          <span style={{ display: "none" }}>Đang chuẩn bị</span>
          <span className="count" style={{ display: "block" }}>
            {formatTimeSecond(dataGame?.time)}
          </span>
        </div>
      </div>
      <div className="game-navbar bg-f result__head">
        <div className="nav-item result__head-item" />
        <div className="nav-item result__head-item">
          Kỳ hàng hiện tại <b>{dataGame?.prev_game?.game_no}</b>
        </div>
        <div
          className="nav-item result__head-item open-info"
          onClick={() => setModalInfo(true)}
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </div>
      </div>
      <div className="current-result">
        <div className="open-award-list-num">
          {get(dataGame, "prev_game.result", []).map((item, index) => (
            <div key={`item-${index?.toString()}`} className="red-ball">
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="level">
        {games?.map((item) => (
          <div
            className={classNames("level__item game_type_0 ", {
              active: activeTab === item?.room_id,
            })}
            onClick={() => handleChangeGame(item?.room_id)}
            key={item?.room_id}
          >
            {item?.name}
          </div>
        ))}
      </div>
      <div className="bet__row">
        {Object.keys(bet_list)?.map((key) => (
          <div
            className={classNames("bet__row-item ", {
              active: item_type === key,
            })}
            key={key}
            onClick={() => handleChangeType(key)}
          >
            {bet_list[key]}
          </div>
        ))}
      </div>
      <div className="wrap-bet__items">
        {Object.keys(bet_types)?.map((key) => (
          <div
            className={classNames("bet__item bg-v1 ", {
              active: item_bet === key,
            })}
            key={key}
            onClick={() => handleOpenBet(key)}
          >
            {bet_types[key]}
          </div>
        ))}
      </div>
      {/* {openModalNotify && (
        <ModalNotify
          open={openModalNotify}
          onClose={() => setModalNotify(false)}
        />
      )} */}
      {openModalBet && (
        <ModalBet
          open={openModalBet}
          onClose={() => setModalBet(false)}
          dataGame={dataGame}
          item_bet={item_bet}
          item_type={item_type}
          pushNotify={pushNotify}
        />
      )}
      {openModalInfo && (
        <ModalInfo open={openModalInfo} onClose={() => setModalInfo(false)} />
      )}
    </div>
  );
}

export default Game;
