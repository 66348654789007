import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import classNames from "classnames";

export default function ItemBall({ selectItem, handleChooseItem }) {
  const {
    authStore: { item_ball },
  } = useStores();

  return (
    <div className="bet-items ">
      {item_ball?.map((item) => (
        <div
          className={classNames("bet-item", {
            active: selectItem.findIndex((obj) => obj.code === item?.code) > -1,
          })}
          onClick={() =>
            handleChooseItem({
              ...item,
              code: item?.code,
            })
          }
          key={item?.code}
        >
          <b>{item?.name}</b>x<span>{item?.rate}</span>
        </div>
      ))}
    </div>
  );
}
