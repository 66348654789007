import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Portal } from "react-portal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { formatNumber } from "_common/utils/formatValue";
import { useNotify } from "_common/component/NotifyProvider";
import { GameApi } from "states/api/game";
import { get } from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ItemBall from "./ItemBall";
import ItemNumber from "./ItemNumber";

export default function ModalBetGame({
  gameInfo,
  gameDetail,
  timeEnd,
  open,
  onClose,
}) {
  const queryClient = useQueryClient();
  const {
    authStore: { general },
    toastStore,
  } = useStores();

  const { dataGame, newResult, pushNotify } = useNotify();

  const [selectItem, setSelectItem] = useState([]);
  const [valueBet, setValueBet] = useState(0);
  const [activeTab, setActiveTab] = useState("code1");

  useEffect(() => {
    if (dataGame?.status === "end_game") {
      setSelectItem([]);
      setValueBet(0);
    }
  }, [dataGame]);

  const finalTotal = useMemo(() => {
    const count = selectItem?.length || 0;
    const betRate = Number(get(general, "money_rate", 1)) || 1;

    return count * betRate * valueBet;
  }, [selectItem, general, valueBet]);

  const finalTotalProfit = useMemo(() => {
    const maxValueOfY = Math.max(...selectItem.map((o) => Number(o.rate)), 0);

    return maxValueOfY * valueBet;
  }, [selectItem, valueBet]);

  const listBall = useMemo(
    () => selectItem?.map((obj) => obj?.name)?.join(" - "),
    [selectItem]
  );

  const { mutate: onSubmit } = useMutation(
    () =>
      GameApi.betGame({
        params: {
          game_id: gameInfo?.id,
          game: gameDetail,
          items: selectItem,
          final_total: finalTotal,
          amount: valueBet,
          type: "bet",
        },
      }),
    {
      onSuccess: (res) => {
        if (typeof pushNotify === "function") {
          pushNotify({
            type: "betGame",
            status: "new_bet",
          });
        }
        onClose();
        setValueBet(Number(get(general, "bet_default", 20000)));
        setSelectItem([]);
        queryClient.invalidateQueries(["get_profile"]);
        toastStore.success(res?.data?.msg || "Đặt cược thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        toastStore.error(errorMessage);
      },
    }
  );

  const handleSubmitForm = () => {
    if (timeEnd) return toastStore.error("Phiên game đang chuẩn bị đóng");
    const minBet = Number(get(general, "min_bet", 20000));
    const maxBet = Number(get(general, "max_bet", 100000));
    if (finalTotal <= 0) return toastStore.error("Vui lòng chọn các lựa chọn");
    if (finalTotal < minBet) {
      toastStore.error(`Vui lòng đặt cược lớn hơn ${formatNumber(minBet)}`);
      return null;
    }

    if (finalTotal > maxBet) {
      toastStore.error(`Vui lòng đặt cược nhỏ hơn ${formatNumber(maxBet)}`);
      return null;
    }

    if (finalTotal > 0) {
      onSubmit();
    }

    return null;
  };

  const handleChooseItem = (item) => {
    const cloneData = [...selectItem];
    const checkIndex = cloneData.findIndex((obj) => obj.code === item.code);

    if (checkIndex > -1) {
      cloneData.splice(checkIndex, 1);
    } else {
      const count = cloneData.filter(
        (current) => typeof current?.code === "number"
      );

      if (count?.length >= 12 && typeof item.code === "number")
        return toastStore.error("Chỉ đặt tối đa 12 số trong một phiên");

      cloneData.push(item);
    }

    setSelectItem(cloneData);
    return null;
  };

  return (
    <Portal>
      <div
        className="modal modal-bet-game"
        style={{ display: open && "block" }}
      >
        <div className="mask" onClick={onClose} />
        <div className="modal-content modal-history">
          <div className="modal-body">
            <div className="tabs">
              <div
                className={classNames("tab", {
                  active: activeTab === "code1",
                })}
                onClick={() => setActiveTab("code1")}
              >
                Bình thường
              </div>
              <div
                className={classNames("tab", {
                  active: activeTab === "code2",
                })}
                onClick={() => setActiveTab("code2")}
              >
                Đặc biệt
              </div>
            </div>

            {activeTab === "code1" && (
              <ItemBall
                selectItem={selectItem}
                handleChooseItem={handleChooseItem}
              />
            )}
            {activeTab === "code2" && (
              <ItemNumber
                selectItem={selectItem}
                handleChooseItem={handleChooseItem}
              />
            )}
            <div className="actions">
              <Link to="/payment/deposit">Nạp tiền</Link>
              <Link to="/payment/withdraw">Rút tiền</Link>
              <Link to="/histories/game">Hồ sơ giao dịch</Link>
            </div>
            <div className="btn-action">
              <span>Mua điểm:</span>
              <input
                className="input-bet"
                type="number"
                value={valueBet}
                onChange={(e) => setValueBet(e?.target?.value)}
                inputMode="decimal"
                placeholder="Vui lòng nhập số điểm giao dịch"
              />
              <button
                type="button"
                className="btn-bet"
                onClick={() => handleSubmitForm()}
              >
                <b>GIAO DỊCH</b>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}
