import React from "react";

import HeaderTitle from "_common/component/Layout/Header/HeaderTitle";
import { useStores } from "_common/hooks";

function Level1Page() {
  const {
    authStore: { vip1_image },
  } = useStores();
  return (
    <>
      <HeaderTitle title="Cơ chế thành viên" />
      <div className="page-container">
        <div className="level-page">
          <img src={vip1_image} alt="" />
        </div>
      </div>
    </>
  );
}

export default Level1Page;
