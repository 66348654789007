import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { observer } from "mobx-react-lite";
import PageLoading from "_common/component/PageLoading";
import { GameApi } from "states/api/game";
import Notice from "pages/Home/Notice";

import GameInfo from "./GameInfo";
import GameBoard from "./GameBoard";
import GameHeader from "./GameHeader";
import GameFooter from "./GameFooter";
import GameHis from "./GameHis";

const PK3Page = observer(() => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams();
  const {
    authStore: { games, general, logo },
  } = useStores();
  const { dataGame } = useNotify();

  const gameId = params?.id;
  const [isLoading, setLoading] = useState(true);
  const [selectItem, setSelectItem] = useState([]);

  const gameDetail = useMemo(
    () => games.find((obj) => obj?.id === gameId),
    [games, gameId]
  );

  const { data: gameInfo, refetch } = useQuery(
    ["game", "detail", gameDetail],
    () =>
      GameApi.getGame({
        params: {
          page: 1,
          limit: 10,
          category: gameDetail?.category,
          code: gameDetail?.code,
          game_id: gameDetail?.id,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const timeEnd = useMemo(() => dataGame?.time <= 5, [dataGame]);

  const timeStart = useMemo(() => dataGame?.time > 0, [dataGame]);

  useEffect(() => {
    refetch();
  }, []);

  if (!gameDetail?.category) return <PageLoading />;

  return (
    <div className="game-pk3">
      <div className="board-game">
        <GameHeader gameInfo={gameInfo} />
        <GameInfo
          gameDetail={gameDetail}
          gameInfo={gameInfo}
          dataGame={dataGame}
          timeEnd={timeEnd}
          timeStart={timeStart}
        />
        <div className="game-board">
          <Notice />
          <GameHis gameInfo={gameInfo} dataGame={dataGame} />
        </div>

        <GameFooter
          timeEnd={timeEnd}
          timeStart={timeStart}
          gameInfo={gameInfo}
          gameDetail={gameDetail}
          dataGame={dataGame}
          setSelectItem={setSelectItem}
          selectItem={selectItem}
        />
      </div>
    </div>
  );
});

export default PK3Page;
