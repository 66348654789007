import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { formatNumber } from "_common/utils/formatValue";
import { Link } from "react-router-dom";

import { useStores } from "_common/hooks";

import huiyuan from "assets/img/huiyuan.png";
import ABC from "assets/img/ACB.png";

import ListBanner from "./ListBanner";
import Notice from "./Notice";
import Profit from "./Profit";

const Home = observer(() => {
  const {
    authStore: { user_wallet },
  } = useStores();

  return (
    <>
      <div className="adm-nav-bar layout-navbar">
        <div className="adm-nav-bar-left" role="button">
          <div className="adm-nav-bar-back">
            <span aria-hidden="true">
              SỐ DƯ: {formatNumber(user_wallet?.amount)}
            </span>
          </div>
        </div>
        <div className="adm-nav-bar-title" />
        <div className="adm-nav-bar-right" />
      </div>
      <div className="page-container">
        <div className="home-page">
          <ListBanner />
          <Notice />
          <div className="statistics">
            <div className="left">
              <div className="vol-count">
                <i />
                <span>
                  <span>
                    Người dùng{" "}
                    <em>
                      7715
                      <em>
                        tỷ đồng Việt Nam
                        <span />
                      </em>
                    </em>
                  </span>
                </span>
              </div>
              <div className="user-count">
                <i />
                <span>
                  <span>
                    Số lượng
                    <em>
                      154562<em>người</em>
                    </em>
                  </span>
                </span>
              </div>
            </div>
            <div className="right">
              <div className="value">
                <span>98</span>%
              </div>
              <div className="label">Lợi nhuận</div>
            </div>
          </div>
          <div className="vip-desc">
            <Link to="/level1">
              <img src={huiyuan} alt="" />
            </Link>
          </div>
          <div className="room-entry">
            <Link to="/level">
              <img src={ABC} alt="" />
            </Link>
          </div>
          <Profit />
        </div>
      </div>
    </>
  );
});

export default Home;
