import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";

import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { useNotify } from "_common/component/NotifyProvider";
import { GameApi } from "states/api/game";
import { get } from "lodash";
import { formatNumber } from "_common/utils/formatValue";
import ModalBetGame from "./ModalBetGame";

const GameFooter = observer(({ gameInfo, gameDetail, timeEnd }) => {
  const queryClient = useQueryClient();
  const {
    authStore: { user, item_ball, item_number, general },
    toastStore,
  } = useStores();

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="bg-footer">
        <div className="btn-action">
          <button type="button" className="btn-bet">
            <b>Làm mới</b>
          </button>
          <input
            className="input-bet"
            type="number"
            readOnly
            inputMode="decimal"
            placeholder="Mua nhanh"
            onClick={() => setOpenModal(true)}
          />
          <button type="button" className="btn-bet">
            <b>GIAO DỊCH</b>
          </button>
        </div>
      </div>
      {openModal && (
        <ModalBetGame
          gameInfo={gameInfo}
          gameDetail={gameDetail}
          timeEnd={timeEnd}
          onClose={() => setOpenModal(false)}
          open={openModal}
        />
      )}
    </>
  );
});

export default GameFooter;
