import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { useStores } from "_common/hooks";

function ModalInfo({ open, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-full"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <div>Quy định</div>
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="content">
              <div className="rules__content">
                <p>
                  Mở thưởng theo thứ tự tự từ trái qua phải bắt đầu từ{" "}
                  <b>Chục Ngàn</b>,<b>Ngàn</b>, <b>Trăm</b>, <b>Chục</b>,
                  <b>Đơn vị</b>
                </p>
                <p>
                  {" "}
                  Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy
                  trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý
                  khách nhận được phần quà ngẫu nhiên may mắn từ Web, khi rút
                  tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như
                  sau:{" "}
                </p>
                <ul>
                  <li>
                    Hạn mức rút tài khoản dưới 200 triệu tương ứng 15% phí
                  </li>
                  <li>
                    Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí
                  </li>
                  <li>
                    Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalInfo;
