import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faEnvelopeOpenText,
  faInfo,
  faPlus,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import { formatNumber } from "_common/utils/formatValue";

const GameHeader = observer(({ gameInfo }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    authStore: { games, user },
  } = useStores();

  return (
    <div className="game-header">
      <div className="left" onClick={() => navigate(-1)}>
        Trở về
      </div>
      <h2>Công kích - Phòng đặc biệt</h2>
      <div className="right">
        <button type="button" className="btn-info">
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
    </div>
  );
});

export default GameHeader;
