import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStores } from "_common/hooks";

import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import classNames from "classnames";

const GameBoard = observer(
  ({ dataGame, gameInfo, selectItem = [], setSelectItem }) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const {
      authStore: { general, item_ball, item_number },
      toastStore,
    } = useStores();

    const handleChooseItem = (item) => {
      const cloneData = [...selectItem];
      const checkIndex = cloneData.findIndex((obj) => obj.code === item.code);

      if (checkIndex > -1) {
        cloneData.splice(checkIndex, 1);
      } else {
        const count = cloneData.filter(
          (current) => typeof current?.code === "number"
        );

        if (count?.length >= 12 && typeof item.code === "number")
          return toastStore.error("Chỉ đặt tối đa 12 số trong một phiên");

        cloneData.push(item);
      }

      setSelectItem(cloneData);

      return null;
    };

    return (
      <div className="bg-game">
        <div className="list-ball">
          {item_ball?.map((item) => (
            <div
              key={item?.code}
              className={classNames("item", {
                select:
                  selectItem.findIndex((obj) => obj.code === item?.code) > -1,
              })}
              onClick={() =>
                handleChooseItem({
                  ...item,
                  code: item?.code,
                })
              }
            >
              <h5>{item?.name}</h5>
              <p>{item?.rate}</p>
            </div>
          ))}
          {item_number?.map((item) => (
            <div
              key={item?.code}
              className={classNames("item", {
                select:
                  selectItem.findIndex((obj) => obj.code === item?.code) > -1,
              })}
              onClick={() =>
                handleChooseItem({
                  ...item,
                  code: item?.code,
                })
              }
            >
              <h5>{item?.name}</h5>
              <p>{item?.rate}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

export default GameBoard;
