export const paymentStatusText = {
  pending: "đang chờ",
  complete: "thành công",
  error: "thất bại",
  reject: "thất bại",
};

export const paymentTypeText = {
  withdraw: "Rút tiền",
  deposit: "Quy đổi",
  bonus: "Phần thưởng",
};

export const paymentStatusColor = {
  pending: "yellow",
  complete: "green",
  error: "red",
};

export const gameTypeText = {
  bet1: "Quần áo",
  bet2: "Mũ",
  bet3: "Trang sức",
  bet4: "Đồng hồ",
  bet5: "Giày dép",
};

export const gameBetItemType = {
  code1: "Xuất",
  code2: "Nhập",
};

export const gameLevelText = {
  level_1: "DIOR",
  level_2: "GUCCI",
  level_3: "CHANEL",
};

export const positionKeyText = {
  agency: "Đại lý",
  user: "Thành viên",
  admin: "Quản trị viên",
  super: "Quản trị viên",
};

export const gameBetText = {
  big: "Tài",
  small: "Xỉu",
  odd: "Lẻ",
  even: "Chẵn",
};

export const gameStatusText = {
  win: "Giao dịch thành công",
  loss: "Giao dịch thất bại",
  betGame: "Đang chờ kết quả",
};
