import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { get } from "lodash";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";

const listBetItem = [20, 30, 50, 100, 500, 1000, 50000, 100000];

function ModalBet({
  open,
  dataGame,
  item_type,
  item_bet,
  pushNotify,
  onClose,
}) {
  const queryClient = useQueryClient();
  const {
    authStore: { general },
  } = useStores();

  const [amount, setAmount] = useState(get(general, "bet_default", 20));
  const [selectItem, setSelectItem] = useState();

  const { mutate: onSubmit } = useMutation(
    () =>
      GameApi.betGame({
        params: {
          game_id: dataGame?.game_id,
          amount,
          item_type,
          item_bet,
          type: "bet",
        },
      }),
    {
      onSuccess: (res) => {
        if (typeof pushNotify === "function") {
          pushNotify({
            type: "betGame",
            status: "new_bet",
          });
        }
        setAmount(Number(get(general, "bet_default", 20)));
        onClose();
        queryClient.invalidateQueries(["get_profile"]);
        NotificationManager.success(res?.msg || "Thành công");
      },
      onError: (error) => {
        const errorMessage = error?.message ?? "Vui lòng thử lại!";
        NotificationManager.error(errorMessage);
      },
    }
  );

  const minus = (e) => {
    const defaultBet = Number(get(general, "bet_default", 1));

    if (amount > defaultBet) {
      setAmount(
        (value) => Number(value) - Number(get(general, "bet_default", 1))
      );
    }
  };

  const plus = (e) => {
    setAmount(
      (value) => Number(value) + Number(get(general, "bet_default", 1))
    );
  };

  const handleChangeValue = (value) => {
    setSelectItem(value);
    setAmount(value);
  };

  const handleChangeInput = (event) => {
    setAmount(event?.target?.value);
  };

  const handleSubmitForm = () => {
    const minBet = Number(get(general, "min_bet", 20000));
    const maxBet = Number(get(general, "max_bet", 100000));
    if (!item_type) {
      NotificationManager.error(`Vui lòng chọn mã hàng trước`);
      return null;
    }

    if (!item_bet) {
      NotificationManager.error(`Vui lòng chọn loại hàng trước`);
      return null;
    }

    if (amount < minBet) {
      NotificationManager.error(
        `Vui lòng đặt lệnh lớn hơn ${formatNumber(minBet)}`
      );
      return null;
    }

    if (amount > maxBet) {
      NotificationManager.error(
        `Vui lòng đặt lệnh nhỏ hơn ${formatNumber(maxBet)}`
      );
      return null;
    }

    if (amount > 0) {
      onSubmit();
    }

    return null;
  };

  return (
    <Portal>
      <div
        className="modal modal-bet-game"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="classic-bet-now">
            <div className="title">
              <h4>Chọn số lượng</h4>
            </div>
            <div className="number-input-wrapper">
              <div className="number-input">
                <span onClick={minus} className="minus">
                  -
                </span>
                <input
                  id="quantity"
                  name="quantity"
                  type="number"
                  min="20"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  value={amount}
                  onChange={handleChangeInput}
                />
                <span onClick={plus} className="plus">
                  +
                </span>
              </div>
            </div>
            <div className="formBet__form">
              <div className="formBet__pick">
                {listBetItem.map((item, index) => (
                  <button
                    type="button"
                    className={classNames("formBet__pick-item", {
                      active: selectItem === item,
                    })}
                    key={`item-${index?.toString()}`}
                    onClick={() => handleChangeValue(item)}
                  >
                    <div> {formatNumber(item)}</div>
                  </button>
                ))}
              </div>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-cancel"
                onClick={() => onClose()}
              >
                Huỷ
              </button>
              <button
                type="button"
                className="btn btn-submit"
                onClick={() => handleSubmitForm()}
              >
                Xác nhận
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalBet;
