import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { useStores } from "_common/hooks";
import Game from "./Game";

const GamePage = observer(() => {
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="game-page">
      <Game />
    </div>
  );
});

export default GamePage;
